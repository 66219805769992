<template>
  <div>
    <user-form ref="userForm" :is-loading="isLoading" @submit="submit" />
  </div>
</template>

<script>
import UserForm from "@/views/Panel/views/Users/components/UserForm";
import { mapActions, mapState } from "vuex";
import { mapUserObject } from "@/views/Panel/views/Users/helpers";
export default {
  name: "NewUser",
  components: { UserForm },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      markets: state => state.api.misc.markets
    })
  },
  methods: {
    ...mapActions({
      createUser: "api/management/user/createUser"
    }),
    submit(data) {
      this.isLoading = true;

      this.createUser(mapUserObject(data, this.markets))
        .then(() => {
          this.$root.$emit("refresh-user-list");
          this.$refs.userForm.handleCancel();
          this.$notifier.notify({
            message: "Market created successfully",
            color: "success"
          });
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot create user",
            color: "error"
          });

          if (
            Object.keys(error.response.data.error.fieldErrors).includes("email")
          ) {
            this.$modals.validation({
              content: error.response.data.error.fieldErrors.email.join("/n"),
              title: "Email must be unique"
            });
          }
          console.log({ error, test: error.response.data.error.fieldErrors });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
